'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

var _templateObject = _taggedTemplateLiteral(['\n  width: ', ';\n  border: 1px solid ', ';\n  border-radius: 4px;\n'], ['\n  width: ', ';\n  border: 1px solid ', ';\n  border-radius: 4px;\n']);

var _react = require('react');

var React = _interopRequireWildcard(_react);

var _lodash = require('lodash.debounce');

var _lodash2 = _interopRequireDefault(_lodash);

var _draftJsExportHtml = require('draft-js-export-html');

var _draftJsImportHtml = require('draft-js-import-html');

var _styledComponents = require('styled-components');

var _styledComponents2 = _interopRequireDefault(_styledComponents);

var _draftJs = require('draft-js');

var _uikit = require('uikit');

var _Toolbar = require('./Toolbar');

var _Toolbar2 = _interopRequireDefault(_Toolbar);

var _Media = require('./Media');

var _Media2 = _interopRequireDefault(_Media);

require('draft-js/dist/Draft.css');

require('./RichEditor.css');

var _TextEditorUtils = require('./TextEditorUtils');

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _interopRequireWildcard(obj) { if (obj && obj.__esModule) { return obj; } else { var newObj = {}; if (obj != null) { for (var key in obj) { if (Object.prototype.hasOwnProperty.call(obj, key)) newObj[key] = obj[key]; } } newObj.default = obj; return newObj; } }

function _taggedTemplateLiteral(strings, raw) { return Object.freeze(Object.defineProperties(strings, { raw: { value: Object.freeze(raw) } })); }

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

function _possibleConstructorReturn(self, call) { if (!self) { throw new ReferenceError("this hasn't been initialised - super() hasn't been called"); } return call && (typeof call === "object" || typeof call === "function") ? call : self; }

function _inherits(subClass, superClass) { if (typeof superClass !== "function" && superClass !== null) { throw new TypeError("Super expression must either be null or a function, not " + typeof superClass); } subClass.prototype = Object.create(superClass && superClass.prototype, { constructor: { value: subClass, enumerable: false, writable: true, configurable: true } }); if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass; }

var mediaBlockRenderer = function mediaBlockRenderer(block) {
  if (block.getType() === 'atomic') {
    return {
      component: _Media2.default,
      editable: false
    };
  }

  return null;
};

var TextEditorContainer = function (_React$Component) {
  _inherits(TextEditorContainer, _React$Component);

  function TextEditorContainer(props) {
    _classCallCheck(this, TextEditorContainer);

    var _this = _possibleConstructorReturn(this, (TextEditorContainer.__proto__ || Object.getPrototypeOf(TextEditorContainer)).call(this, props));

    _this._convertValueToHTML = function (input) {};

    _this._focus = function () {
      return _this.refs.editor.focus();
    };

    _this._handleChange = function (editorState, callback) {
      var newEditorState = editorState;

      var content = editorState.getCurrentContent();
      var key = editorState.getSelection().getFocusKey();
      var type = content.getBlockForKey(key).getType();

      if (type === 'atomic') {
        var keyAfter = content.getKeyAfter(key);
        var newSelection = _draftJs.SelectionState.createEmpty(keyAfter);
        newEditorState = _draftJs.EditorState.forceSelection(editorState, newSelection);
      }

      _this.setState({ editorState: newEditorState }, function () {
        _this._convertValueToHTML(editorState);

        if (callback) {
          callback();
        }
      });
    };

    _this._handleInsertImage = function (imgUrl) {
      var editorState = _this.state.editorState;

      var contentState = editorState.getCurrentContent();
      var contentStateWithEntity = contentState.createEntity('image', 'IMMUTABLE', { src: imgUrl });
      var entityKey = contentStateWithEntity.getLastCreatedEntityKey();
      var newEditorState = _draftJs.EditorState.set(editorState, {
        currentContent: contentStateWithEntity
      });

      _this._handleChange(_draftJs.AtomicBlockUtils.insertAtomicBlock(newEditorState, entityKey, ' '), function () {
        setTimeout(function () {
          _this._toggleInsertImage();
        }, 0);
      });
    };

    _this._toggleInsertImage = function () {
      var showImagePicker = _this.state.showImagePicker;


      _this.setState({ showImagePicker: !showImagePicker });
    };

    _this._toggleBlockType = function (blockType) {
      var editorState = _this.state.editorState;


      var newEditorState = _draftJs.RichUtils.toggleBlockType(editorState, blockType);

      _this._handleChange(newEditorState);
    };

    _this._toggleInlineStyle = function (inlineStyle) {
      var editorState = _this.state.editorState;


      var newEditorState = _draftJs.RichUtils.toggleInlineStyle(editorState, inlineStyle);

      _this._handleChange(newEditorState);
    };

    _this._handleUndoRedo = function (_ref) {
      var type = _ref.type;
      var editorState = _this.state.editorState;


      switch (type) {
        case 'undo':
          _this._handleChange(_draftJs.EditorState.undo(editorState));
          break;
        case 'redo':
          _this._handleChange(_draftJs.EditorState.redo(editorState));
          break;
        default:
      }
    };

    _this._getBlockStyle = function (block) {
      switch (block.getType()) {
        case 'blockquote':
          return 'RichEditor-blockquote';
        default:
          return '';
      }
    };

    _this._handleKeyCommand = function (command, editorState) {
      var newEditorState = _draftJs.RichUtils.handleKeyCommand(editorState, command);

      if (newEditorState) {
        _this._handleChange(newEditorState);
        return 'handled';
      }

      return 'not-handled';
    };

    _this._mapKeyToEditorCommand = function (e) {
      if (e.keyCode === 9 /* TAB */) {
          var newEditorState = _draftJs.RichUtils.onTab(e, _this.state.editorState, 4 /* maxDepth */
          );

          if (newEditorState !== _this.state.editorState) {
            _this._handleChange(newEditorState);
          }

          return '';
        }

      return (0, _draftJs.getDefaultKeyBinding)(e);
    };

    var _this$props = _this.props,
        setHTML = _this$props.setHTML,
        getHTML = _this$props.getHTML,
        debounce = _this$props.debounce;


    _this.state = {
      editorState: setHTML ? _draftJs.EditorState.createWithContent((0, _draftJsImportHtml.stateFromHTML)(setHTML)) : _draftJs.EditorState.createEmpty(),
      showImagePicker: false
    };

    var convertValueToHTML = function convertValueToHTML(value) {
      var html = (0, _draftJsExportHtml.stateToHTML)(value.getCurrentContent(), {
        defaultBlockTag: 'div'
      });

      getHTML(html);
    };

    _this._convertValueToHTML = (0, _lodash2.default)(convertValueToHTML, debounce ? debounce : 100);
    return _this;
  }

  _createClass(TextEditorContainer, [{
    key: 'render',
    value: function render() {
      var _this2 = this;

      var _props = this.props,
          width = _props.width,
          _props$urlTarget = _props.urlTarget,
          urlTarget = _props$urlTarget === undefined ? _TextEditorUtils.imageUploadPath : _props$urlTarget,
          _props$bucket = _props.bucket,
          bucket = _props$bucket === undefined ? '' : _props$bucket,
          _props$presetid = _props.presetid,
          presetid = _props$presetid === undefined ? '' : _props$presetid;
      var _state = this.state,
          editorState = _state.editorState,
          showImagePicker = _state.showImagePicker;


      var className = 'RichEditor-editor';
      var contentState = editorState.getCurrentContent();

      if (!contentState.hasText() && contentState.getBlockMap().first().getType() !== 'unstyled') {
        className += ' RichEditor-hidePlaceholder';
      }

      return React.createElement(
        React.Fragment,
        null,
        React.createElement(
          StyledWrapperFlex,
          { width: width, flexDirection: 'column' },
          React.createElement(
            _uikit.Box,
            { style: styles.richEditorRoot },
            React.createElement(_Toolbar2.default, {
              editorState: editorState,
              toggleBlockType: this._toggleBlockType,
              toggleInlineStyle: this._toggleInlineStyle,
              toggleInsertImage: this._toggleInsertImage,
              handleInsertImage: this._handleInsertImage,
              showImagePicker: showImagePicker,
              onUndoRedo: this._handleUndoRedo,
              urlTarget: urlTarget,
              bucket: bucket,
              presetid: presetid
            }),
            React.createElement(
              _uikit.Box,
              {
                className: className,
                onClick: function onClick() {
                  _this2._focus();
                } },
              React.createElement(_draftJs.Editor, {
                blockStyleFn: this._getBlockStyle,
                customStyleMap: styleMap,
                editorState: editorState,
                handleKeyCommand: this._handleKeyCommand,
                keyBindingFn: this._mapKeyToEditorCommand,
                onChange: this._handleChange,
                placeholder: 'Type something here',
                ref: 'editor',
                blockRendererFn: mediaBlockRenderer
              })
            )
          )
        )
      );
    }
  }, {
    key: 'componentDidUpdate',
    value: function componentDidUpdate(prevProps) {
      var _this3 = this;

      var _props2 = this.props,
          setHTML = _props2.setHTML,
          refreshFlag = _props2.refreshFlag,
          toggleRefreshFlag = _props2.toggleRefreshFlag;


      var createEditorState = function createEditorState() {
        _this3.setState({
          editorState: setHTML ? _draftJs.EditorState.createWithContent((0, _draftJsImportHtml.stateFromHTML)(prevProps.setHTML)) : _draftJs.EditorState.createEmpty()
        });
      };

      if (refreshFlag) {
        if (prevProps.refreshFlag) {
          toggleRefreshFlag();
          createEditorState();
        } else {
          createEditorState();
        }
      }
    }
  }]);

  return TextEditorContainer;
}(React.Component);

var StyledWrapperFlex = (0, _styledComponents2.default)(_uikit.Flex)(_templateObject, function (props) {
  return props.width && props.width;
}, _uikit.theme.colors.alto);

var styleMap = {
  CODE: {
    backgroundColor: 'rgba(0, 0, 0, 0.05)',
    fontFamily: '"Inconsolata", "Menlo", "Consolas", monospace',
    fontSize: 16,
    padding: 2
  }
};

var styles = {
  // style for whole text editor (including toolbar)
  richEditorRoot: {
    background: '#fff',
    border: '1px solid #ddd',
    fontFamily: 'Source Sans Pro, sans-serif',
    fontSize: 16
  }
};

exports.default = TextEditorContainer;