// @flow
import * as React from 'react';
import {Flex, Text} from 'uikit';
import {SelectFilter, Pagination} from 'uikit/admin';
import styled from 'styled-components';
import qs from 'query-string';
import {withRouter} from 'react-router-dom';
import {getQueriesObjectFromLocation} from '.';

import type {RouterHistory, Location} from 'react-router-dom';
import {ensureInt} from 'utils';

type Entry = {
  label: string,
  value: number,
};

type Props = {
  entries: Array<Entry>,
  totalPage: number,
  totalItems: number,
  history: RouterHistory,
  location: Location,
  onChange?: (val: {page: number, pageSize: number}) => any,
  noPushState?: boolean,
  controlled: boolean,
  page: number,
};

type State = {
  pageSize: number,
  page: number,
};

class TablePagination extends React.Component<Props, State> {
  constructor(props) {
    super(props);
    const {page, pageSize} = getQueriesObjectFromLocation(props);
    this.state = {
      pageSize: parseInt(pageSize, 0) || props.entries[0]['value'] || 0,
      page: parseInt(page, 0) || 1,
    };
  }

  componentDidUpdate(prevProps) {
    const {location} = this.props;
    if (prevProps.location.search !== location.search) {
      const {page} = qs.parse(location.search);
      this.setState({page: ensureInt(page, 1)});
    }
  }

  // need to fix use use cb or promise

  handleEntries = (val) => {
    const {onChange} = this.props;
    this.setState({pageSize: val, page: 1}, () => {
      this.changeQuery();
      onChange && onChange(this.state);
    });
  };

  goToPage = (val) => {
    const {onChange} = this.props;
    this.setState({page: val}, () => {
      this.changeQuery();
      onChange && onChange(this.state);
    });
  };

  changeQuery = () => {
    const {noPushState, location} = this.props;

    if (noPushState) {
      return;
    }

    const queries = getQueriesObjectFromLocation(this.props);
    const {pathname} = location;

    this.props.history.replace({
      pathname,
      search: qs.stringify({...queries, ...this.state}),
    });
  };

  render() {
    const {
      entries,
      totalPage,
      totalItems,
      controlled,
      page: updatedPage,
    } = this.props;
    const {pageSize, page} = this.state;

    return (
      <StyledFlex mb={2} justifyContent="space-between" bg="greySmoke" px={3}>
        <Flex alignItems="center">
          <Text children="Show" mr={2} />
          <SelectFilter
            onChange={(val) => this.handleEntries(val)}
            name="number"
            value={pageSize}
            options={entries}
          />
          <Flex alignItems="flex-end">
            <Text ml={2} children="of" />
            <Text ml={1} children={totalItems} bold />
            <Text ml={1} children="result" />
          </Flex>
        </Flex>
        <Pagination
          currentPage={!!controlled && updatedPage ? updatedPage : page}
          totalPage={totalPage}
          onClick={this.goToPage}
        />
      </StyledFlex>
    );
  }
}

const StyledFlex = styled(Flex)`
  border-radius: 4px;
  border: 1px solid rgba(0, 0, 0, 0.1);
`;

export default withRouter(TablePagination);
