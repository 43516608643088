module.exports = {
  // todo: will be deleted once released
  // '/ruangkerja/theme-config?userCompanySerial=CMP-TEST-RABAC': {
  //   status: 'success',
  //   data: {
  //     colors: {
  //       primary: "#000000",
  //       secondary: "#000000",
  //       textOnPrimary: "light",
  //       textOnSecondary: "light",
  //       iconOnPrimary: "light",
  //       iconOnSecondary: "light",
  //       missionSidebar: "#000000"
  //     },
  //     images: {
  //       onboarding: {
  //         companyLogo: '',
  //         backgroundImages: [],
  //       },
  //       login: {
  //         appCompanyLogo: '',
  //         webCompanyLogo: '',
  //         backgroundImages: [
  //           {
  //             imageURL: '',
  //             description: {
  //                 en: '',
  //                 id: '',
  //             },
  //           }
  //         ],
  //       },
  //       learningPlan: {
  //         backgroundImages: [
  //           {
  //             imageURL: '',
  //             description: {
  //                 en: '',
  //                 id: '',
  //             },
  //           },
  //         ],
  //       }
  //     }
  //   }
  // },
  // '/ruangkerja/cms/content-library': {
  //   data: {
  //     currentPage: 1,
  //     items: [
  //       {
  //         serial: 'COURSE-IYFFVV3M',
  //         users: 827,
  //         assignedUsers: 18,
  //         name: 'Browning Marks',
  //         provider: 'Skill Academy',
  //         category: 'qui',
  //       },
  //       {
  //         serial: 'COURSE-IYFFVV3M',
  //         users: 341,
  //         assignedUsers: 39,
  //         name: 'Lenore Caldwell',
  //         provider: 'Skill Academy',
  //         category: 'occaecat',
  //       },
  //       {
  //         serial: 'COURSE-IYFFVV3M',
  //         users: 682,
  //         assignedUsers: 40,
  //         name: 'Wooten Holt',
  //         provider: 'Skill Academy',
  //         category: 'mollit',
  //       },
  //       {
  //         serial: 'COURSE-IYFFVV3M',
  //         users: 829,
  //         assignedUsers: 37,
  //         name: 'Norton Trujillo',
  //         provider: 'Skill Academy',
  //         category: 'non',
  //       },
  //       {
  //         serial: 'COURSE-IYFFVV3M',
  //         users: 763,
  //         assignedUsers: 68,
  //         name: 'Bobbie Mcbride',
  //         provider: 'Skill Academy',
  //         category: 'amet',
  //       },
  //       {
  //         serial: 'COURSE-IYFFVV3M',
  //         users: 891,
  //         assignedUsers: 25,
  //         name: 'Rachelle Jimenez',
  //         provider: 'Skill Academy',
  //         category: 'eu',
  //       },
  //       {
  //         serial: 'COURSE-IYFFVV3M',
  //         users: 920,
  //         assignedUsers: 41,
  //         name: 'Haynes Rollins',
  //         provider: 'Skill Academy',
  //         category: 'ea',
  //       },
  //       {
  //         serial: 'COURSE-IYFFVV3M',
  //         users: 426,
  //         assignedUsers: 21,
  //         name: 'Christian Dawson',
  //         provider: 'Skill Academy',
  //         category: 'cillum',
  //       },
  //       {
  //         serial: 'COURSE-IYFFVV3M',
  //         users: 860,
  //         assignedUsers: 74,
  //         name: 'Janine Acevedo',
  //         provider: 'Skill Academy',
  //         category: 'qui',
  //       },
  //       {
  //         serial: 'COURSE-IYFFVV3M',
  //         users: 972,
  //         assignedUsers: 85,
  //         name: 'Paige Reynolds',
  //         provider: 'Skill Academy',
  //         category: 'pariatur',
  //       },
  //     ],
  //     totalItems: 10,
  //     totalPage: 1,
  //   },
  //   status: 'success',
  //   message: 'success',
  // },
  // '/ruangkerja/cms/content-quota': {
  //   status: 'success',
  //   data: {
  //     type: 'FIXED',
  //     deadlinePackage: '',
  //     quota: 20,
  //     usedQuota: 19, // nil if type is flexi
  //   },
  //   message: 'success',
  // },
  // '/ruangkerja/cms/map-course-provider': {
  //   status: 'success',
  //   data: {
  //     OTS: {
  //       label: 'OTS',
  //       color: '#eaeaea',
  //     },
  //     SKILLACADEMY: {
  //       label: 'Skill Academy',
  //       color: '#eaeaea',
  //     },
  //     LINKEDIN: {
  //       label: 'Linkedin',
  //       color: '#eaeaea',
  //     },
  //   },
  //   message: 'success',
  // },
};
