'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _react = require('react');

var React = _interopRequireWildcard(_react);

var _svgInlineReact = require('svg-inline-react');

var _svgInlineReact2 = _interopRequireDefault(_svgInlineReact);

var _uikit = require('uikit');

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _interopRequireWildcard(obj) { if (obj && obj.__esModule) { return obj; } else { var newObj = {}; if (obj != null) { for (var key in obj) { if (Object.prototype.hasOwnProperty.call(obj, key)) newObj[key] = obj[key]; } } newObj.default = obj; return newObj; } }

var IconBold = function IconBold(_ref) {
  var color = _ref.color;

  var iconBoldSVG = '\n    <svg xmlns="http://www.w3.org/2000/svg" width="11" height="14" viewBox="0 0 11 14">\n        <g fill="none" fill-rule="evenodd">\n            <path d="M-3-1h16v16H-3z"/>\n            <path fill="' + color + '" fill-rule="nonzero" d="M8.4 6.548c.943-.651 1.612-1.702 1.612-2.69C10.012 1.682 8.307 0 6.105 0H0v13.5h6.876c2.047 0 3.624-1.64 3.624-3.655a3.611 3.611 0 0 0-2.1-3.298zM2.93 2.41h2.93c.811 0 1.466.646 1.466 1.446 0 .8-.655 1.447-1.466 1.447H2.93V2.41zm3.419 8.678H2.93V8.196H6.35c.81 0 1.465.646 1.465 1.447 0 .8-.654 1.446-1.465 1.446z"/>\n        </g>\n    </svg>\n    ';

  return React.createElement(
    _uikit.Box,
    null,
    React.createElement(_svgInlineReact2.default, { src: iconBoldSVG })
  );
};

exports.default = IconBold;