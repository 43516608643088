'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _templateObject = _taggedTemplateLiteral(['\n  justify-content: center;\n  align-items: center;\n  min-width: 16px;\n  height: 16px;\n  :hover {\n    cursor: pointer;\n  }\n'], ['\n  justify-content: center;\n  align-items: center;\n  min-width: 16px;\n  height: 16px;\n  :hover {\n    cursor: pointer;\n  }\n']),
    _templateObject2 = _taggedTemplateLiteral(['\n  color: ', ';\n  font-weight: 900;\n'], ['\n  color: ', ';\n  font-weight: 900;\n']);

var _react = require('react');

var React = _interopRequireWildcard(_react);

var _styledComponents = require('styled-components');

var _styledComponents2 = _interopRequireDefault(_styledComponents);

var _uikit = require('uikit');

var _IconBold = require('./IconBold');

var _IconBold2 = _interopRequireDefault(_IconBold);

var _IconImage = require('./IconImage');

var _IconImage2 = _interopRequireDefault(_IconImage);

var _IconItalic = require('./IconItalic');

var _IconItalic2 = _interopRequireDefault(_IconItalic);

var _IconOL = require('./IconOL');

var _IconOL2 = _interopRequireDefault(_IconOL);

var _IconUL = require('./IconUL');

var _IconUL2 = _interopRequireDefault(_IconUL);

var _IconUnderline = require('./IconUnderline');

var _IconUnderline2 = _interopRequireDefault(_IconUnderline);

var _IconUndo = require('./IconUndo');

var _IconUndo2 = _interopRequireDefault(_IconUndo);

var _IconRedo = require('./IconRedo');

var _IconRedo2 = _interopRequireDefault(_IconRedo);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _interopRequireWildcard(obj) { if (obj && obj.__esModule) { return obj; } else { var newObj = {}; if (obj != null) { for (var key in obj) { if (Object.prototype.hasOwnProperty.call(obj, key)) newObj[key] = obj[key]; } } newObj.default = obj; return newObj; } }

function _taggedTemplateLiteral(strings, raw) { return Object.freeze(Object.defineProperties(strings, { raw: { value: Object.freeze(raw) } })); }
// $FlowFixMe


var ToolbarItem = function ToolbarItem(props) {
  var type = props.type,
      _props$isActive = props.isActive,
      isActive = _props$isActive === undefined ? false : _props$isActive,
      onMouseDown = props.onMouseDown;


  var _renderItemBasedOnProps = function _renderItemBasedOnProps() {
    var color = isActive ? '#609DDB' : '#425981';

    switch (type) {
      // Block style

      case 'ol':
        {
          return React.createElement(_IconOL2.default, { color: color });
        }
      case 'ul':
        {
          return React.createElement(_IconUL2.default, { color: color });
        }
      case 'h1':
        {
          return React.createElement(
            StyledItemBox,
            { color: color },
            'H1'
          );
        }
      case 'h2':
        {
          return React.createElement(
            StyledItemBox,
            { color: color },
            'H2'
          );
        }
      case 'h3':
        {
          return React.createElement(
            StyledItemBox,
            { color: color },
            'H3'
          );
        }
      case 'h4':
        {
          return React.createElement(
            StyledItemBox,
            { color: color },
            'H4'
          );
        }
      case 'h5':
        {
          return React.createElement(
            StyledItemBox,
            { color: color },
            'H5'
          );
        }
      case 'blockquote':
        {
          return React.createElement(
            StyledItemBox,
            { color: color },
            'Blockquote'
          );
        }
      case 'codeblock':
        {
          return React.createElement(
            StyledItemBox,
            { color: color },
            'Code Block'
          );
        }

      // Inline style

      case 'bold':
        {
          return React.createElement(_IconBold2.default, { color: color });
        }
      case 'italic':
        {
          return React.createElement(_IconItalic2.default, { color: color });
        }
      case 'underline':
        {
          return React.createElement(_IconUnderline2.default, { color: color });
        }
      case 'code':
        {
          return React.createElement(
            StyledItemBox,
            { color: color },
            'Monospace'
          );
        }

      // EditorState Modifier

      case 'undo':
        {
          return React.createElement(_IconUndo2.default, { color: color });
        }
      case 'redo':
        {
          return React.createElement(_IconRedo2.default, { color: color });
        }

      // Misc

      case 'image':
        {
          return React.createElement(_IconImage2.default, { color: color });
        }

      default:
        {
          return null;
        }
    }
  };

  return React.createElement(
    StyledIconContainerBox,
    {
      onMouseDown: function (_onMouseDown) {
        function onMouseDown(_x) {
          return _onMouseDown.apply(this, arguments);
        }

        onMouseDown.toString = function () {
          return _onMouseDown.toString();
        };

        return onMouseDown;
      }(function (e) {
        e.preventDefault();
        onMouseDown ? onMouseDown() : function () {};
      }) },
    _renderItemBasedOnProps()
  );
};

var StyledIconContainerBox = (0, _styledComponents2.default)(_uikit.Flex)(_templateObject);

var StyledItemBox = (0, _styledComponents2.default)(_uikit.Box)(_templateObject2, function (props) {
  return props.color;
});

exports.default = ToolbarItem;