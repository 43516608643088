'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.UploadedInfo = exports.InputFile = undefined;

var _InputFile = require('./InputFile');

var _InputFile2 = _interopRequireDefault(_InputFile);

var _UploadedInfo = require('./UploadedInfo');

var _UploadedInfo2 = _interopRequireDefault(_UploadedInfo);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

exports.InputFile = _InputFile2.default;
exports.UploadedInfo = _UploadedInfo2.default;