'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.isSrtFileName = exports.isImageURL = exports.isValidURL = exports.createConfig = undefined;

var _axios = require('axios');

var _axios2 = _interopRequireDefault(_axios);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

const CancelToken = _axios2.default.CancelToken;

// TODO: refactor createConfig
// after a talk with @hanif, it looks like that passing an instance like this is not considered a best practice, so the API might need to be changed in the future.
// change createConfig return value to not use any
// => currently hard, because of axios

const createConfig = exports.createConfig = (token, instance, withProgress, onCancel) => {
  if (withProgress) {
    return {
      headers: {
        Authorization: token,
        'Content-Type': 'multipart/form-data'
      },
      onUploadProgress: progressEvent => {
        let percentCompleted = Math.round(progressEvent.loaded * 100 / progressEvent.total);
        instance.setState({
          progress: percentCompleted,
          uploading: true
        });
      },
      cancelToken: new CancelToken(cancel => {
        onCancel && typeof onCancel === 'function' && onCancel(cancel);
      })
    };
  } else {
    return {
      headers: {
        Authorization: token,
        'Content-Type': 'multipart/form-data'
      }
    };
  }
};

const isValidURL = exports.isValidURL = str => {
  const pattern = /^(?:(?:https?|ftp):\/\/)(?:\S+(?::\S*)?@)?(?:(?!10(?:\.\d{1,3}){3})(?!127(?:\.​\d{1,3}){3})(?!169\.254(?:\.\d{1,3}){2})(?!192\.168(?:\.\d{1,3}){2})(?!172\.(?:1[​6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1​,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z\u00​a1-\uffff0-9]+-?)*[a-z\u00a1-\uffff0-9]+)(?:\.(?:[a-z\u00a1-\uffff0-9]+-?)*[a-z\u​00a1-\uffff0-9]+)*(?:\.(?:[a-z\u00a1-\uffff]{2,})))(?::\d{2,5})?(?:\/[^\s]*)?$/i;

  if (!pattern.test(str)) {
    return false;
  } else {
    return true;
  }
};

const isImageURL = exports.isImageURL = imageURL => !!imageURL && imageURL.match(/\.(jpeg|jpg|gif|png)$/) != null;

const isSrtFileName = exports.isSrtFileName = fileName => !!fileName && fileName.match(/\.(srt)$/) != null;