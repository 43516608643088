'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _react = require('react');

var React = _interopRequireWildcard(_react);

var _styledComponents = require('styled-components');

var _styledComponents2 = _interopRequireDefault(_styledComponents);

var _uikit = require('uikit');

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _interopRequireWildcard(obj) { if (obj && obj.__esModule) { return obj; } else { var newObj = {}; if (obj != null) { for (var key in obj) { if (Object.prototype.hasOwnProperty.call(obj, key)) newObj[key] = obj[key]; } } newObj.default = obj; return newObj; } }

// $FlowFixMe
const ProgressBar = props => {
  const { progress } = props;
  return React.createElement(
    StyledProgressContainerRelative,
    { bg: 'alabaster' },
    React.createElement(StyledProgressIndicatorBox, { w: `${progress}%`, bg: 'green' })
  );
};

const StyledProgressContainerRelative = (0, _styledComponents2.default)(_uikit.Relative)`
  height: 11px;
  border-radius: 5px;
  border: 1px solid #dbdbdb;
`;

const StyledProgressIndicatorBox = (0, _styledComponents2.default)(_uikit.Box)`
  height: 10px;
  transition: width 1s;
  border-radius: 5px;
`;

exports.default = ProgressBar;