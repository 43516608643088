'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _react = require('react');

var React = _interopRequireWildcard(_react);

var _svgInlineReact = require('svg-inline-react');

var _svgInlineReact2 = _interopRequireDefault(_svgInlineReact);

var _uikit = require('uikit');

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _interopRequireWildcard(obj) { if (obj && obj.__esModule) { return obj; } else { var newObj = {}; if (obj != null) { for (var key in obj) { if (Object.prototype.hasOwnProperty.call(obj, key)) newObj[key] = obj[key]; } } newObj.default = obj; return newObj; } }

var IconRedo = function IconRedo(_ref) {
  var color = _ref.color;

  var iconRedoSVG = '\n    <svg width="13px" height="13px" viewBox="0 0 13 13" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">\n      <!-- Generator: Sketch 50.2 (55047) - http://www.bohemiancoding.com/sketch -->\n      <title>icn_redo</title>\n      <desc>Created with Sketch.</desc>\n      <defs></defs>\n      <g id="text-editor-new" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">\n        <g id="popup_create_question_new(new-text-editor)" transform="translate(-351.000000, -406.000000)" fill="' + color + '">\n          <g id="Group" transform="translate(65.000000, 397.000000)">\n            <g id="form-copy" transform="translate(49.000000, 0.000000)">\n              <g id="Screen-Shot-2016-01-19-at-11.19.52-AM">\n                <g id="icn_redo" transform="translate(243.500000, 15.500000) scale(-1, 1) translate(-243.500000, -15.500000) translate(237.000000, 9.000000)">\n                  <path d="M0,13 L0,10.1764706 L7.475,10.1764706 C9.09702983,10.1764706 10.4,8.76148035 10.4,7 C10.4,5.23851965 9.09702983,3.82352941 7.475,3.82352941 L2.6,3.82352941 L2.6,1 L7.475,1 C10.5329702,1 13,3.67912741 13,7 C13,10.3208726 10.5329702,13 7.475,13 L0,13 Z" id="Combined-Shape" fill-rule="nonzero"></path>\n                  <polygon id="Shape" points="3 5 0 2.5 3 0"></polygon>\n                </g>\n              </g>\n            </g>\n          </g>\n        </g>\n      </g>\n    </svg>\n  ';

  return React.createElement(
    _uikit.Box,
    null,
    React.createElement(_svgInlineReact2.default, { src: iconRedoSVG })
  );
};

exports.default = IconRedo;