'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _react = require('react');

var React = _interopRequireWildcard(_react);

var _styledComponents = require('styled-components');

var _styledComponents2 = _interopRequireDefault(_styledComponents);

var _uikit = require('uikit');

var _ProgressBar = require('./ProgressBar');

var _ProgressBar2 = _interopRequireDefault(_ProgressBar);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _interopRequireWildcard(obj) { if (obj && obj.__esModule) { return obj; } else { var newObj = {}; if (obj != null) { for (var key in obj) { if (Object.prototype.hasOwnProperty.call(obj, key)) newObj[key] = obj[key]; } } newObj.default = obj; return newObj; } }

class UploadedInfo extends React.Component {
  render() {
    const {
      uploading,
      progress,
      uploaded,
      value,
      extraContent,
      assetURL,
      isInitialPreview,
      onCancelUpload,
      customPreview
    } = this.props;

    return React.createElement(
      React.Fragment,
      null,
      uploading && React.createElement(
        StyledInfoRelative,
        { w: 1, p: 3, bg: 'white' },
        React.createElement(
          _uikit.Flex,
          { justifyContent: 'space-between' },
          React.createElement(_uikit.Text, { mb: 3, color: 'steelBlue', bold: true, childrend: 'Uploading' }),
          React.createElement(_uikit.Text, { color: 'steelBlue', children: `${progress} %` })
        ),
        React.createElement(_ProgressBar2.default, { progress: progress }),
        React.createElement(
          _uikit.Flex,
          { justifyContent: 'flex-end', mt: 3 },
          React.createElement(_uikit.ButtonOutline, {
            children: 'cancel upload',
            type: 'button',
            color: 'red',
            onClick: onCancelUpload
          })
        )
      ),
      !uploaded && !uploading && value && (customPreview ? customPreview : isInitialPreview && React.createElement(
        StyledInfoRelative,
        { w: 1, p: 3, bg: 'white' },
        React.createElement(StyledImageBox, { bg: value, width: '215px' })
      )),
      uploaded && value && (customPreview ? customPreview : isInitialPreview && React.createElement(
        StyledInfoRelative,
        { w: 1, p: 3, children: 'uploaded', bg: 'white' },
        assetURL && React.createElement(StyledImageBox, { bg: `${assetURL}`, width: '215px' }),
        extraContent && React.createElement(
          _uikit.Box,
          { mt: 3 },
          extraContent
        )
      ))
    );
  }
}

exports.default = UploadedInfo;
// $FlowFixMe

const StyledInfoRelative = (0, _styledComponents2.default)(_uikit.Relative)`
  box-shadow: 0 0 0 1px ${props => _uikit.util.statusColor(props)};
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
  top: -1px;
`;

const StyledImageBox = (0, _styledComponents2.default)(_uikit.Box)`
  background: url(${props => `"${props.bg}"`}) center no-repeat white;
  background-size: cover;
  height: 152px;
`;