'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.TextEditor = undefined;

var _react = require('react');

var React = _interopRequireWildcard(_react);

var _TextEditorContainer = require('./TextEditorContainer');

var _TextEditorContainer2 = _interopRequireDefault(_TextEditorContainer);

var _TextEditorUtils = require('./TextEditorUtils');

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _interopRequireWildcard(obj) { if (obj && obj.__esModule) { return obj; } else { var newObj = {}; if (obj != null) { for (var key in obj) { if (Object.prototype.hasOwnProperty.call(obj, key)) newObj[key] = obj[key]; } } newObj.default = obj; return newObj; } }

var TextEditor = exports.TextEditor = function TextEditor(props) {
  var height = props.height,
      width = props.width,
      getHTML = props.getHTML,
      setHTML = props.setHTML,
      debounce = props.debounce,
      refreshFlag = props.refreshFlag,
      toggleRefreshFlag = props.toggleRefreshFlag,
      _props$urlTarget = props.urlTarget,
      urlTarget = _props$urlTarget === undefined ? _TextEditorUtils.imageUploadPath : _props$urlTarget,
      _props$bucket = props.bucket,
      bucket = _props$bucket === undefined ? '' : _props$bucket,
      _props$presetid = props.presetid,
      presetid = _props$presetid === undefined ? '' : _props$presetid;

  return React.createElement(_TextEditorContainer2.default, {
    getHTML: getHTML,
    setHTML: setHTML,
    debounce: debounce,
    refreshFlag: refreshFlag,
    toggleRefreshFlag: toggleRefreshFlag,
    height: height,
    width: width,
    urlTarget: urlTarget,
    bucket: bucket,
    presetid: presetid
  });
};

TextEditor.defaultProps = {
  width: '20rem'
};

exports.default = TextEditor;