'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _react = require('react');

var React = _interopRequireWildcard(_react);

var _svgInlineReact = require('svg-inline-react');

var _svgInlineReact2 = _interopRequireDefault(_svgInlineReact);

var _uikit = require('uikit');

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _interopRequireWildcard(obj) { if (obj && obj.__esModule) { return obj; } else { var newObj = {}; if (obj != null) { for (var key in obj) { if (Object.prototype.hasOwnProperty.call(obj, key)) newObj[key] = obj[key]; } } newObj.default = obj; return newObj; } }

var IconImage = function IconImage(_ref) {
  var color = _ref.color;

  var iconImageSVG = '\n    <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14">\n      <g fill="none" fill-rule="evenodd">\n          <path d="M-1-1h16v16H-1z"/>\n          <path fill="' + color + '" d="M8 12h4L8 3 5.882 7.765 5 6l-3 6h6zM0 0h14v14H0V0z"/>\n      </g>\n    </svg>\n    ';

  return React.createElement(
    _uikit.Box,
    null,
    React.createElement(_svgInlineReact2.default, { src: iconImageSVG })
  );
};

exports.default = IconImage;