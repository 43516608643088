'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.defaultImageBasePath = exports.imageUploadPath = exports.extractStyleTypes = undefined;

var _react = require('react');

var React = _interopRequireWildcard(_react);

var _ToolbarItem = require('./ToolbarItem');

var _ToolbarItem2 = _interopRequireDefault(_ToolbarItem);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _interopRequireWildcard(obj) { if (obj && obj.__esModule) { return obj; } else { var newObj = {}; if (obj != null) { for (var key in obj) { if (Object.prototype.hasOwnProperty.call(obj, key)) newObj[key] = obj[key]; } } newObj.default = obj; return newObj; } }

var STYLE_TYPES = [
// Block Styles

{
  render: function render(_ref) {
    var isActive = _ref.isActive,
        onMouseDown = _ref.onMouseDown;
    return React.createElement(_ToolbarItem2.default, { type: 'h1', isActive: isActive, onMouseDown: onMouseDown });
  },
  style: 'header-one',
  type: 'block'
}, {
  render: function render(_ref2) {
    var isActive = _ref2.isActive,
        onMouseDown = _ref2.onMouseDown;
    return React.createElement(_ToolbarItem2.default, { type: 'h2', isActive: isActive, onMouseDown: onMouseDown });
  },
  style: 'header-two',
  type: 'block'
}, {
  render: function render(_ref3) {
    var isActive = _ref3.isActive,
        onMouseDown = _ref3.onMouseDown;
    return React.createElement(_ToolbarItem2.default, { type: 'h3', isActive: isActive, onMouseDown: onMouseDown });
  },
  style: 'header-three',
  type: 'block'
}, {
  render: function render(_ref4) {
    var isActive = _ref4.isActive,
        onMouseDown = _ref4.onMouseDown;
    return React.createElement(_ToolbarItem2.default, { type: 'h4', isActive: isActive, onMouseDown: onMouseDown });
  },
  style: 'header-four',
  type: 'block'
}, {
  render: function render(_ref5) {
    var isActive = _ref5.isActive,
        onMouseDown = _ref5.onMouseDown;
    return React.createElement(_ToolbarItem2.default, { type: 'h5', isActive: isActive, onMouseDown: onMouseDown });
  },
  style: 'header-five',
  type: 'block'
}, {
  render: function render(_ref6) {
    var isActive = _ref6.isActive,
        onMouseDown = _ref6.onMouseDown;
    return React.createElement(_ToolbarItem2.default, {
      type: 'blockquote',
      isActive: isActive,
      onMouseDown: onMouseDown
    });
  },
  style: 'blockquote',
  type: 'block'
}, {
  render: function render(_ref7) {
    var isActive = _ref7.isActive,
        onMouseDown = _ref7.onMouseDown;
    return React.createElement(_ToolbarItem2.default, { type: 'ul', isActive: isActive, onMouseDown: onMouseDown });
  },
  style: 'unordered-list-item',
  type: 'block'
}, {
  render: function render(_ref8) {
    var isActive = _ref8.isActive,
        onMouseDown = _ref8.onMouseDown;
    return React.createElement(_ToolbarItem2.default, { type: 'ol', isActive: isActive, onMouseDown: onMouseDown });
  },
  style: 'ordered-list-item',
  type: 'block'
}, {
  render: function render(_ref9) {
    var isActive = _ref9.isActive,
        onMouseDown = _ref9.onMouseDown;
    return React.createElement(_ToolbarItem2.default, {
      type: 'codeblock',
      isActive: isActive,
      onMouseDown: onMouseDown
    });
  },
  style: 'code-block',
  type: 'block'
},

// Inline Styles

{
  render: function render(_ref10) {
    var isActive = _ref10.isActive,
        onMouseDown = _ref10.onMouseDown;
    return React.createElement(_ToolbarItem2.default, { type: 'bold', isActive: isActive, onMouseDown: onMouseDown });
  },
  style: 'BOLD',
  type: 'inline'
}, {
  render: function render(_ref11) {
    var isActive = _ref11.isActive,
        onMouseDown = _ref11.onMouseDown;
    return React.createElement(_ToolbarItem2.default, {
      type: 'italic',
      isActive: isActive,
      onMouseDown: onMouseDown
    });
  },
  style: 'ITALIC',
  type: 'inline'
}, {
  render: function render(_ref12) {
    var isActive = _ref12.isActive,
        onMouseDown = _ref12.onMouseDown;
    return React.createElement(_ToolbarItem2.default, {
      type: 'underline',
      isActive: isActive,
      onMouseDown: onMouseDown
    });
  },
  style: 'UNDERLINE',
  type: 'inline'
}, {
  render: function render(_ref13) {
    var isActive = _ref13.isActive,
        onMouseDown = _ref13.onMouseDown;
    return React.createElement(_ToolbarItem2.default, { type: 'code', isActive: isActive, onMouseDown: onMouseDown });
  },
  style: 'CODE',
  type: 'inline'
}];

var extractStyleTypes = exports.extractStyleTypes = function extractStyleTypes(typesToExtract) {
  return typesToExtract.reduce(function (accumulator, type, index) {
    var foundType = STYLE_TYPES.find(function (styleType) {
      return styleType.style === type;
    });

    if (foundType) {
      accumulator.push(foundType);
    }

    return accumulator;
  }, []);
};

var imageUploadPath = exports.imageUploadPath = 'https://gw-staging.ruangguru.com/api/v3/upload-image';
var defaultImageBasePath = exports.defaultImageBasePath = 'https://core-ruangguru.s3.amazonaws.com/';