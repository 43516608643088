module.exports = {
  /*
  'ruangkerja/cms/company': {
    data: {
      status: 'success',
      data: {
        items: [
          {
            name: 'Pertamina Persero',
            serial: 'parent1',
            type: 'company',
            child: [],
          },
        ],
      },
    },
  },
  'ruangkerja/cms/department': {
    data: {
      status: 'success',
      data: {
        items: [
          {
            name: 'Pertamina Persero',
            serial: 'parent1',
            type: 'company',
            child: [
              {
                name: 'Hulu',
                serial: 'departement1',
                type: 'department',
              },
            ],
          },
        ],
      },
    },
  },
  */
  /*
  'ruangkerja/cms/tag': {
    status: 'success',
    message: 'success',
  },
  */
  /*
  'ruangkerja/cms/user': {
    data: {
      serial: 'user3',
    },
  },
  */
  /*
  'ruangkerja/cms/company-info': {
    data: {
      status: 'successs',
      data: {
        name: 'Pertamina',
        shortname: 'Pertamina',
        logo:
          'https://core-ruangguru.s3.amazonaws.com/assets/miscellaneous/image_fszjb2_5091.png',
        logoDashboard:
          'https://core-ruangguru.s3.amazonaws.com/assets/miscellaneous/image_vflrlm_6285.png',
        coverImage:
          'https://core-ruangguru.s3.amazonaws.com/assets/miscellaneous/image_5itlio_2132.png',
        companyserial: 'company1',
      },
    },
  },
  */

  'ruangkerja/cms/new-company': {
    data: {
      status: 'success',
    },
  },

  'ruangkerja/ruker-questions': {
    status: 'success',
    data: [
      {
        serial: 'QU-ntlmW3lS',
        type: 'Single choice',
        contents: 'What is lorem ipsume?',
        content_definitions: {
          text: 'lorem ipsume sit amet dolor. sit amet dolor',
          video_serial: '',
          video_url: '',
        },
        owner_id: 'COMP-XYZ',
        source: 'Ruang Kerja',
        status: 'Publish',
        answers: [
          {
            contents: 'Lorem ipsume sit amet dolor.',
            right_answers: true,
          },
          {
            contents: 'Lorem ipsume sit amet dolor.',
            right_answers: false,
          },
          {
            contents: 'Lorem ipsume sit amet dolor.',
            right_answers: false,
          },
          {
            contents: 'Lorem ipsume sit amet dolor.',
            right_answers: false,
          },
        ],
        correct_answers: [0],
        tags: ['COURSE-2U401297'],
        flag: 'ruker',
        created: {
          by: 'UOC-CA11D1361C',
          date: '2018-05-09 13:46:08',
        },
        updated: {
          by: 'UOC-CA11D1361C',
          date: '2018-05-09 13:46:08',
        },
      },
    ],
    messages: 'Success inserting new value',
  },
  'ruangkerja/cms/course/assign/user/upload': {
    data: {
      userAddedCount: 10,
      userExistingCount: 100,
      userExistingPendingSerial: '',
      userInvalidCount: 15,
      userInvalidPendingSerial: 1,
    },
  },
  /* 'ruangkerja/cms/course/content/topik': params => {
   *   const {name} = params;
   *   return {
   *     status: '200',
   *     message: 'success',
   *     data: {
   *       serial: name,
   *     },
   *   };
   * }, */
  /* 'ruangkerja/cms/course/content/subtopik': params => {
   *   const {name} = params;
   *   return {
   *     status: '200',
   *     message: 'success',
   *     data: {
   *       serial: name,
   *     },
   *   };
   * }, */
  /* 'ruangkerja/cms/course/content/topik/delete': params => {
   *   const {id} = params;
   *   return {
   *     status: '200',
   *     message: 'success',
   *     data: {},
   *   };
   * },
   * 'ruangkerja/cms/course/content/subtopik/delete': params => {
   *   const {id} = params;
   *   return {
   *     status: '200',
   *     message: 'success',
   *     data: {},
   *   };
   * }, */
  'ruangkerja/cms/course/pre-test/delete': (params) => {
    const {id} = params;
    return {
      status: '200',
      message: 'success',
      data: {},
    };
  },
  'ruangkerja/cms/course/post-test/delete': (params) => {
    const {id} = params;
    return {
      status: '200',
      message: 'success',
      data: {},
    };
  },
};
