'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

var _templateObject = _taggedTemplateLiteral(['\n  background-color: #f1f4f9;\n'], ['\n  background-color: #f1f4f9;\n']);

var _react = require('react');

var React = _interopRequireWildcard(_react);

var _styledComponents = require('styled-components');

var _styledComponents2 = _interopRequireDefault(_styledComponents);

var _uikit = require('uikit');

var _adminInputfile = require('admin-inputfile');

var _StyleControls = require('./StyleControls');

var _StyleControls2 = _interopRequireDefault(_StyleControls);

var _ToolbarItem = require('../ToolbarItem');

var _ToolbarItem2 = _interopRequireDefault(_ToolbarItem);

var _TextEditorUtils = require('../TextEditorUtils');

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _interopRequireWildcard(obj) { if (obj && obj.__esModule) { return obj; } else { var newObj = {}; if (obj != null) { for (var key in obj) { if (Object.prototype.hasOwnProperty.call(obj, key)) newObj[key] = obj[key]; } } newObj.default = obj; return newObj; } }

function _taggedTemplateLiteral(strings, raw) { return Object.freeze(Object.defineProperties(strings, { raw: { value: Object.freeze(raw) } })); }

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

function _possibleConstructorReturn(self, call) { if (!self) { throw new ReferenceError("this hasn't been initialised - super() hasn't been called"); } return call && (typeof call === "object" || typeof call === "function") ? call : self; }

function _inherits(subClass, superClass) { if (typeof superClass !== "function" && superClass !== null) { throw new TypeError("Super expression must either be null or a function, not " + typeof superClass); } subClass.prototype = Object.create(superClass && superClass.prototype, { constructor: { value: subClass, enumerable: false, writable: true, configurable: true } }); if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass; }
// $FlowFixMe


var Toolbar = function (_React$Component) {
  _inherits(Toolbar, _React$Component);

  function Toolbar(props) {
    _classCallCheck(this, Toolbar);

    var _this = _possibleConstructorReturn(this, (Toolbar.__proto__ || Object.getPrototypeOf(Toolbar)).call(this, props));

    _this.state = {
      isFileValid: null,
      isUploadError: null
    };
    return _this;
  }

  _createClass(Toolbar, [{
    key: 'render',
    value: function render() {
      var _this2 = this;

      var _props = this.props,
          editorState = _props.editorState,
          toggleBlockType = _props.toggleBlockType,
          toggleInlineStyle = _props.toggleInlineStyle,
          toggleInsertImage = _props.toggleInsertImage,
          handleInsertImage = _props.handleInsertImage,
          showImagePicker = _props.showImagePicker,
          onUndoRedo = _props.onUndoRedo,
          _props$urlTarget = _props.urlTarget,
          urlTarget = _props$urlTarget === undefined ? _TextEditorUtils.imageUploadPath : _props$urlTarget,
          _props$bucket = _props.bucket,
          bucket = _props$bucket === undefined ? '' : _props$bucket,
          _props$presetid = _props.presetid,
          presetid = _props$presetid === undefined ? '' : _props$presetid;
      var _state = this.state,
          isFileValid = _state.isFileValid,
          isUploadError = _state.isUploadError;


      return React.createElement(
        StyledToolbarWrapperBox,
        { px: '16px', py: '16px' },
        React.createElement(
          _uikit.Flex,
          null,
          React.createElement(_StyleControls2.default, {
            editorState: editorState,
            toggleBlockType: toggleBlockType,
            toggleInlineStyle: toggleInlineStyle,
            items: ['BOLD', 'ITALIC', 'UNDERLINE', 'unordered-list-item', 'ordered-list-item']
          }),
          React.createElement(
            _uikit.Box,
            { mr: 3 },
            React.createElement(_ToolbarItem2.default, {
              type: 'image',
              isActive: showImagePicker,
              onMouseDown: toggleInsertImage
            })
          ),
          React.createElement(
            _uikit.Box,
            { mr: 3 },
            React.createElement(_ToolbarItem2.default, {
              type: 'undo',
              isActive: false,
              onMouseDown: function onMouseDown() {
                onUndoRedo({ type: 'undo' });
              }
            })
          ),
          React.createElement(
            _uikit.Box,
            null,
            React.createElement(_ToolbarItem2.default, {
              type: 'redo',
              isActive: false,
              onMouseDown: function onMouseDown() {
                onUndoRedo({ type: 'redo' });
              }
            })
          )
        ),
        showImagePicker && React.createElement(
          _uikit.Box,
          { pt: '12px' },
          React.createElement(_adminInputfile.InputFile, {
            name: 'Question image upload',
            placeholder: 'Please select an image.',
            validation: {
              format: ['jpeg'],
              dimension: {
                min: { w: 1, h: 1 },
                max: { w: 1000, h: 1000 }
              }
            },
            urlTarget: urlTarget,
            bucket: bucket,
            presetid: presetid,
            onUploadSuccess: function onUploadSuccess(res) {
              var imageUrl = urlTarget === _TextEditorUtils.imageUploadPath ? '' + _TextEditorUtils.defaultImageBasePath + res.data[0]['file'] : res.data.s3URL;
              handleInsertImage(imageUrl);
            },
            onLoadFileEnd: function onLoadFileEnd(file, validationStatus) {
              if (file) {
                // do something when validation successful
                _this2.setState({ isFileValid: true, isUploadError: false });
              } else {
                // do something when validation failed
                _this2.setState({ isFileValid: false, isUploadError: false });
              }
            },
            onError: function onError(err, file) {
              _this2.setState({ isFileValid: true, isUploadError: true });
            },
            accept: '.jpg'
          }),
          !isUploadError && React.createElement(
            _uikit.Text,
            {
              fontSize: '12px',
              mt: 1,
              color: isFileValid || isFileValid === null ? '#858585' : 'red' },
            '*',
            isFileValid || isFileValid === null ? '' : 'error: ',
            'Image should be in .jpg format, and has a maximal resolution of 1000x1000 pixels.'
          ),
          isUploadError && React.createElement(
            _uikit.Text,
            { fontSize: '12px', mt: 1, color: 'red' },
            'Error on uploading image. This might be because of the problem in your internet connection or internal server error. Please check your internet connection and try again or contact our technical support.'
          )
        )
      );
    }
  }]);

  return Toolbar;
}(React.Component);

var StyledToolbarWrapperBox = (0, _styledComponents2.default)(_uikit.Box)(_templateObject);

exports.default = Toolbar;