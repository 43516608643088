'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _react = require('react');

var React = _interopRequireWildcard(_react);

var _svgInlineReact = require('svg-inline-react');

var _svgInlineReact2 = _interopRequireDefault(_svgInlineReact);

var _uikit = require('uikit');

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _interopRequireWildcard(obj) { if (obj && obj.__esModule) { return obj; } else { var newObj = {}; if (obj != null) { for (var key in obj) { if (Object.prototype.hasOwnProperty.call(obj, key)) newObj[key] = obj[key]; } } newObj.default = obj; return newObj; } }

var IconUL = function IconUL(_ref) {
  var color = _ref.color;

  var iconULSVG = '\n    <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14">\n       <g fill="none" fill-rule="evenodd">\n         <path d="M-1-1h16v16H-1z"/>\n         <path fill="' + color + '" d="M3 12h11v2H3v-2zm-2 2a1 1 0 1 1 0-2 1 1 0 0 1 0 2zm2-6h11v2H3V8zm-2 2a1 1 0 1 1 0-2 1 1 0 0 1 0   2zm2-6h11v2H3V4zM1 6a1 1 0 1 1 0-2 1 1 0 0 1 0 2zm2-6h11v2H3V0zM1 2a1 1 0 1 1 0-2 1 1 0 0 1 0 2z"/>\n      </g>\n    </svg>\n  ';

  return React.createElement(
    _uikit.Box,
    null,
    React.createElement(_svgInlineReact2.default, { src: iconULSVG })
  );
};

exports.default = IconUL;