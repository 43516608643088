'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _react = require('react');

var React = _interopRequireWildcard(_react);

var _svgInlineReact = require('svg-inline-react');

var _svgInlineReact2 = _interopRequireDefault(_svgInlineReact);

var _uikit = require('uikit');

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _interopRequireWildcard(obj) { if (obj && obj.__esModule) { return obj; } else { var newObj = {}; if (obj != null) { for (var key in obj) { if (Object.prototype.hasOwnProperty.call(obj, key)) newObj[key] = obj[key]; } } newObj.default = obj; return newObj; } }

var IconOL = function IconOL(_ref) {
  var color = _ref.color;

  var iconOLSVG = '\n    <svg xmlns="http://www.w3.org/2000/svg" width="14" height="15" viewBox="0 0 14 15">\n      <g fill="none" fill-rule="evenodd">\n          <path d="M-1 0h16v16H-1z"/>\n          <path fill="' + color + '" d="M5 13h9v2H5v-2zm0-4h9v2H5V9zm0-4h9v2H5V5zm0-4h9v2H5V1z"/>\n          <path fill="' + color + '" stroke="' + color + '" stroke-width=".5" d="M1.038 11.47v-.45c.868-.821 1.48-1.406 1.48-1.888 0-.333-.188-.515-.483-.515-.247 0-.434.166-.606.348L1 8.542C1.338 8.182 1.654 8 2.142 8c.67 0 1.127.43 1.127 1.083 0 .574-.537 1.186-1.09 1.765.173-.022.409-.043.57-.043h.686v.665H1.038zM1 4.35v-.628h.728V1.786H1.1v-.48c.364-.069.596-.164.834-.306h.57v2.722h.627v.628H1z"/>\n      </g>\n    </svg>\n  ';

  return React.createElement(
    _uikit.Box,
    null,
    React.createElement(_svgInlineReact2.default, { src: iconOLSVG })
  );
};

exports.default = IconOL;