'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _react = require('react');

var React = _interopRequireWildcard(_react);

var _svgInlineReact = require('svg-inline-react');

var _svgInlineReact2 = _interopRequireDefault(_svgInlineReact);

var _uikit = require('uikit');

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _interopRequireWildcard(obj) { if (obj && obj.__esModule) { return obj; } else { var newObj = {}; if (obj != null) { for (var key in obj) { if (Object.prototype.hasOwnProperty.call(obj, key)) newObj[key] = obj[key]; } } newObj.default = obj; return newObj; } }

var IconItalic = function IconItalic(_ref) {
  var color = _ref.color;

  var iconItalicSVG = '\n    <svg xmlns="http://www.w3.org/2000/svg" width="10" height="14" viewBox="0 0 10 14">\n      <g fill="none" fill-rule="evenodd">\n          <path d="M-3-1h16v16H-3z"/>\n          <path fill="' + color + '" d="M5.052 12H8v2H0v-2h3.005L5.176 2H2V0h8v2H7.223L5.052 12z"/>\n      </g>\n    </svg>\n  ';

  return React.createElement(
    _uikit.Box,
    null,
    React.createElement(_svgInlineReact2.default, { src: iconItalicSVG })
  );
};

exports.default = IconItalic;