module.exports = {
  'ruangkerja/video/222': {
    data: {
      serial: '222',
    },
    status: 'success',
  },
  /*
  'ruangkerja/cms/company': {
    data: {
      status: 'success',
      data: {
        items: [
          {
            name: 'Pertamina Persero (Aksi Delete Company)',
            serial: 'parent1',
            type: 'company',
            child: [],
          },
        ],
      },
    },
  },
  */
  'ruangkerja/cms/department': {
    data: {
      status: 'success',
      data: {
        items: [
          {
            name: 'Pertamina Persero (Aksi Delete Department)',
            serial: 'parent1',
            type: 'company',
            child: [],
          },
        ],
      },
    },
  },
};
