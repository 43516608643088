'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _react = require('react');

var React = _interopRequireWildcard(_react);

var _apiClient = require('api-client');

var _axios = require('axios');

var _axios2 = _interopRequireDefault(_axios);

var _styledComponents = require('styled-components');

var _styledComponents2 = _interopRequireDefault(_styledComponents);

var _inputFileUtils = require('./inputFileUtils');

var _utils = require('utils');

var _uikit = require('uikit');

var _UploadedInfo = require('./UploadedInfo');

var _UploadedInfo2 = _interopRequireDefault(_UploadedInfo);

var _ramda = require('ramda');

var _rukerAdminAuth = require('ruker-admin-auth');

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _interopRequireWildcard(obj) { if (obj && obj.__esModule) { return obj; } else { var newObj = {}; if (obj != null) { for (var key in obj) { if (Object.prototype.hasOwnProperty.call(obj, key)) newObj[key] = obj[key]; } } newObj.default = obj; return newObj; } }

function _asyncToGenerator(fn) { return function () { var gen = fn.apply(this, arguments); return new Promise(function (resolve, reject) { function step(key, arg) { try { var info = gen[key](arg); var value = info.value; } catch (error) { reject(error); return; } if (info.done) { resolve(value); } else { return Promise.resolve(value).then(function (value) { step("next", value); }, function (err) { step("throw", err); }); } } return step("next"); }); }; }
// $FlowFixMe


// in px


// in bytes
const INITIAL_STATE = {
  progress: 0,
  uploading: false,
  uploaded: false,
  validationStatus: null,
  status: '',
  statusMessage: '',
  assetURL: '',
  assetName: ''
};

class InputFile extends React.Component {
  constructor(props) {
    var _this;

    _this = super(props);

    this._isSubtitle = file => {
      const subtitleFormat = ['srt', 'vtt'];

      return subtitleFormat.indexOf(this._getFileType(file)) >= 0;
    };

    this._isImage = file => {
      const imageFormat = ['jpg', 'jpeg', 'png'];

      return imageFormat.indexOf(this._getFileType(file)) >= 0;
    };

    this._isVideo = file => {
      const videoFormat = ['mp4'];

      return videoFormat.indexOf(this._getFileType(file)) >= 0;
    };

    this._isPdf = file => {
      const pdfFormat = ['pdf'];

      return pdfFormat.indexOf(this._getFileType(file)) >= 0;
    };

    this._getTypeFromFileName = name => {
      return name.split('.').pop().toLowerCase();
    };

    this._isDocument = file => {
      const { fileValidation = [] } = this.props;
      const { type, name } = file;

      return fileValidation.some(value => {
        // in some file `type` is not detected, so we get filetype from file's name extension
        const ext = this._getTypeFromFileName(name);
        const fileType = type || ext;
        return value.format === fileType;
      });
    };

    this._handleCancelUpload = () => {
      this._cancelUpload && this._cancelUpload();
    };

    this._getFileType = file => {
      const { type } = file;
      return type.split('/')[1];
    };

    this._getToken = () => {
      const { token } = _rukerAdminAuth.authUtils.getToken();

      if (token) {
        return token;
      }

      const value = '; ' + document.cookie;
      let parts = value.split('; token=');

      if (parts.length == 2) {
        return parts.pop().split(';').shift();
      } else {
        return '';
      }
    };

    this._getImageDimension = reader => {
      // _getImageDimension will return Promise because this we don't want to wait for image to load.

      return new Promise((resolve, reject) => {
        const img = new Image();

        img.src = (0, _utils.ensureString)(reader.result);

        img.onload = () => resolve({
          width: img.width,
          height: img.height
        });
      });
    };

    this._isDocumentValid = file => {
      const { fileValidation = [] } = this.props;

      // size validation for document
      const { type: fileType, size } = file;
      const type = fileType || this._getTypeFromFileName(file.name);
      const isMaxSizeDocValid = fileValidation.some(value => value.format === type && size <= (0, _utils.ensureInt)((0, _utils.ensureObject)(value.size).max));
      const isMinSizeDocValid = fileValidation.some(value => value.format === type && size >= (0, _utils.ensureInt)((0, _utils.ensureObject)(value.size).min));

      if (!isMaxSizeDocValid) {
        this.setState({ validationStatus: 'invalid-size-max' });
        return Promise.resolve(false);
      }

      if (!isMinSizeDocValid) {
        this.setState({ validationStatus: 'invalid-size-min' });
        return Promise.resolve(false);
      }

      this.setState({ validationStatus: 'valid' });
      return Promise.resolve(true);
    };

    this._isFileValid = (validation, fileType, reader, file) => {
      const { format, dimension, size } = validation;

      const { size: fileSize } = file;

      let videoFormat = ['mp4'];
      let imageFormat = ['jpeg', 'jpg', 'png'];

      const isVideoFormat = videoFormat.includes(fileType);
      const isImageFormat = imageFormat.includes(fileType);

      // validation flags

      // size.max present
      const shouldValidateMaxSize = !!size && typeof size === 'object' && !isNaN(size.max);
      // size.min present
      const shouldValidateMinSize = !!size && typeof size === 'object' && !isNaN(size.min);

      const shouldValidateSize = shouldValidateMaxSize || shouldValidateMinSize;

      // dimension.max.w and dimension.max.h present
      const shouldValidateMaxDimension = !!dimension && typeof dimension === 'object' && !!dimension.max && typeof dimension.max === 'object' && !isNaN(dimension.max.w) && !isNaN(dimension.max.h);
      // dimension.min.w and dimension.min.h present
      const shouldValidateMinDimension = !!dimension && typeof dimension === 'object' && !!dimension.min && typeof dimension.min === 'object' && !isNaN(dimension.min.w) && !isNaN(dimension.min.h);

      const shouldValidateOptDimension = (0, _utils.ensureArray)((0, _utils.ensureObject)(dimension).opt).length > 0;

      // validate for certificate, where the image can be longer not wider
      const shouldValidateMaxWidthDimension = !!dimension && typeof dimension === 'object' && !!dimension.max && typeof dimension.max === 'object' && !isNaN(dimension.max.w) && !!isNaN(dimension.max.h);

      const shouldValidateDimension = shouldValidateMaxDimension || shouldValidateMinDimension || shouldValidateMaxWidthDimension || shouldValidateOptDimension;

      // -----
      // format validation
      // -----

      // .srt and .h5p file is not detected in type, so we pass
      if ((0, _ramda.equals)((0, _ramda.intersection)(['h5p', 'srt'], format), format)) {
        this.setState({ validationStatus: 'valid' });
        return Promise.resolve(true);
      }

      if (format.indexOf(fileType) <= -1) {
        this.setState({ validationStatus: 'invalid-format' });
        return Promise.resolve(false);
      }

      // -----
      // size validation
      // -----

      if (shouldValidateSize) {
        if (shouldValidateMaxSize && (0, _utils.ensureInt)(fileSize) > (0, _utils.ensureInt)((0, _utils.ensureObject)(size).max)) {
          this.setState({ validationStatus: 'invalid-size-max' });
          return Promise.resolve(false);
        }

        if (shouldValidateMinSize && (0, _utils.ensureInt)(fileSize) < (0, _utils.ensureInt)((0, _utils.ensureObject)(size).min)) {
          this.setState({ validationStatus: 'invalid-size-min' });
          return Promise.resolve(false);
        }
      }

      // -----
      // dimension validation
      // -----

      if (shouldValidateDimension) {
        if (isImageFormat) {
          const imageDimension = this._getImageDimension(reader);

          return Promise.resolve(imageDimension).then(fileDimension => {
            const { width: fileWidth, height: fileHeight } = fileDimension;

            if (shouldValidateMaxDimension) {
              if (fileWidth > (0, _utils.ensureInt)((0, _utils.ensureObject)((0, _utils.ensureObject)(dimension).max).w) || fileHeight > (0, _utils.ensureInt)((0, _utils.ensureObject)((0, _utils.ensureObject)(dimension).max).h)) {
                this.setState({ validationStatus: 'invalid-dimension-max' });
                return false;
              }
            }

            if (shouldValidateMinDimension) {
              if (fileWidth < (0, _utils.ensureInt)((0, _utils.ensureObject)((0, _utils.ensureObject)(dimension).min).w) || fileHeight < (0, _utils.ensureInt)((0, _utils.ensureObject)((0, _utils.ensureObject)(dimension).min).h)) {
                this.setState({ validationStatus: 'invalid-dimension-min' });
                return false;
              }
            }

            if (shouldValidateMaxWidthDimension) {
              if (fileWidth > (0, _utils.ensureInt)((0, _utils.ensureObject)((0, _utils.ensureObject)(dimension).max).w)) {
                this.setState({ validationStatus: 'invalid-dimension-width-max' });
                return false;
              }
            }

            if (shouldValidateOptDimension) {
              const fileDimensionFound = (0, _utils.ensureArray)((0, _utils.ensureObject)(dimension).opt).filter(item => item.w === fileWidth && item.h === fileHeight) || [];

              if ((0, _utils.ensureArray)(fileDimensionFound).length <= 0) {
                this.setState({ validationStatus: 'invalid-dimension-opt' });
                return false;
              }
            }

            this.setState({ validationStatus: 'valid' });
            return true;
          });
        }
      }

      this.setState({ validationStatus: 'valid' });
      return Promise.resolve(true);
    };

    this._uploadFile = (() => {
      var _ref = _asyncToGenerator(function* (file, urlTarget, description, bucket, presetid) {
        const formData = new FormData();
        const config = (0, _inputFileUtils.createConfig)(_this._getToken(), _this, true);

        formData.set('file', file);
        formData.append('description', description);

        if (!!bucket && bucket !== '') {
          formData.append('bucket', bucket);
        }

        if (!!presetid && presetid !== '') {
          formData.append('presetID', presetid);
        }

        try {
          const response = yield _axios2.default.post(urlTarget, formData, config);
          const { data } = response;
          return data;
        } catch (err) {
          return { err, type: 'error' };
        }
      });

      return function (_x, _x2, _x3, _x4, _x5) {
        return _ref.apply(this, arguments);
      };
    })();

    this._uploadImage = (() => {
      var _ref2 = _asyncToGenerator(function* (file, urlTarget, description, bucket, presetid) {
        const formData = new FormData();
        const onCancel = function (c) {
          _this._cancelUpload = c;
        };
        const config = (0, _inputFileUtils.createConfig)(_this._getToken(), _this, true, onCancel);

        formData.set('file', file);
        formData.set('type', 'image');
        formData.append('description', description);

        if (!!bucket && bucket !== '') {
          formData.append('bucket', bucket);
        }

        if (!!presetid && presetid !== '') {
          formData.append('presetID', presetid);
        }

        try {
          const response = yield _axios2.default.post(urlTarget, formData, config);
          const { data } = response;
          return data;
        } catch (err) {
          return { err, type: 'error' };
        }
      });

      return function (_x6, _x7, _x8, _x9, _x10) {
        return _ref2.apply(this, arguments);
      };
    })();

    this._uploadPdf = (() => {
      var _ref3 = _asyncToGenerator(function* (file, urlTarget) {
        const formData = new FormData();
        const onCancel = function (c) {
          _this._cancelUpload = c;
        };
        const config = (0, _inputFileUtils.createConfig)(_this._getToken(), _this, true, onCancel);

        formData.set('file', file);
        formData.append('presetID', 'ruker_mission_pdf');
        formData.append('bucket', 'ruker-mission-pdf-stag');
        formData.append('contentType', 'application/pdf');

        try {
          const response = yield _axios2.default.post(urlTarget, formData, config);
          const { data } = response;
          return data;
        } catch (err) {
          return { err, type: 'error' };
        }
      });

      return function (_x11, _x12) {
        return _ref3.apply(this, arguments);
      };
    })();

    this._uploadDocument = (() => {
      var _ref4 = _asyncToGenerator(function* (file, urlTarget) {
        const formData = new FormData();
        const onCancel = function (c) {
          _this._cancelUpload = c;
        };
        const config = (0, _inputFileUtils.createConfig)(_this._getToken(), _this, true, onCancel);
        const { presetid = '', bucket = '' } = _this.props;
        const { type } = file;

        formData.set('file', file);
        formData.append('presetID', presetid);
        formData.append('bucket', bucket);
        formData.append('contentType', type);

        try {
          const response = yield _axios2.default.post(urlTarget, formData, config);
          const { data } = response;
          return data;
        } catch (err) {
          return { err, type: 'error' };
        }
      });

      return function (_x13, _x14) {
        return _ref4.apply(this, arguments);
      };
    })();

    this._generateVideoSerial = _asyncToGenerator(function* () {
      const config = (0, _inputFileUtils.createConfig)(_this._getToken(), _this, false);

      try {
        const response = yield _axios2.default.post(`ruangkerja/cms/video`, {}, config);

        const { data } = response;
        return data;
      } catch (err) {
        return { err, type: 'error' };
      }
    });

    this._uploadVideo = (() => {
      var _ref6 = _asyncToGenerator(function* ({
        file,
        filename,
        urlTarget
      }) {
        const formData = new FormData();
        const onCancel = function (c) {
          _this._cancelUpload = c;
        };
        const config = (0, _inputFileUtils.createConfig)(_this._getToken(), _this, true, onCancel);

        formData.set('file', file);
        formData.append('filename', filename);
        formData.append('presetID', 'ruker_mission_video');
        formData.append('bucket', 'ruker-video-input-stag');

        try {
          const response = yield _axios2.default.post(urlTarget, formData, config);

          const { data } = response;

          return data;
        } catch (err) {
          return { err, type: 'error' };
        }
      });

      return function (_x15) {
        return _ref6.apply(this, arguments);
      };
    })();

    this._notifyVideoUploaded = (() => {
      var _ref7 = _asyncToGenerator(function* ({
        serial,
        versionSerial,
        s3Url
      }) {
        const onCancel = function (c) {
          _this._cancelUpload = c;
        };
        const config = (0, _inputFileUtils.createConfig)(_this._getToken(), _this, true, onCancel);

        try {
          const response = yield _axios2.default.post(`/ruangkerja/cms/video/${serial}`, {
            versionSerial,
            s3Url
          }, config);

          const { data } = response;
          return data;
        } catch (err) {
          return { err, type: 'error' };
        }
      });

      return function (_x16) {
        return _ref7.apply(this, arguments);
      };
    })();

    this._handleUploadFileSuccess = (result, file) => {
      const { status, data } = (0, _utils.ensureObject)(result);
      const { onUploadSuccess, onError } = this.props;

      if (status === 'success') {
        onUploadSuccess && typeof onUploadSuccess === 'function' && onUploadSuccess(result, file);

        this.setState({
          status: 'valid',
          progress: 100,
          uploading: false,
          uploaded: true
        });
      }
    };

    this._handleUploadImageSuccess = (result, file) => {
      const { status, data } = (0, _utils.ensureObject)(result);
      const { onUploadSuccess, onError, bucket } = this.props;

      let assetURL = '';

      if (!!bucket && bucket !== '') {
        assetURL = data.s3URL;
      } else {
        assetURL = (0, _utils.ensureString)((0, _utils.ensureObject)((0, _utils.ensureArray)(data)[0])['fullpath']);
      }

      if (status === 'success') {
        onUploadSuccess && onUploadSuccess(result, file);
        this.setState({
          status: 'valid',
          progress: 100,
          uploading: false,
          uploaded: true,
          assetURL
        });
      }
    };

    this._handleUploadVideoSuccess = (result, file) => {
      const { status, data } = (0, _utils.ensureObject)(result);
      const { onUploadSuccess, onError } = this.props;

      if (status === 'success') {
        onUploadSuccess && onUploadSuccess(data, file);
        this.setState({
          status: 'valid',
          progress: 100,
          uploading: false,
          uploaded: true,
          assetURL: ''
        });
      }
    };

    this._handleUploadFileSuccess = (result, file) => {
      const { status, data } = (0, _utils.ensureObject)(result);
      const { onUploadSuccess, onError } = this.props;

      const assetURL = (0, _utils.ensureString)((0, _utils.ensureObject)((0, _utils.ensureArray)(data)[0])['fullpath']);

      if (status === 'success') {
        onUploadSuccess && onUploadSuccess(result, file);
        this.setState({
          status: 'valid',
          progress: 100,
          uploading: false,
          uploaded: true,
          assetURL
        });
      }
    };

    this._resetProgress = () => {
      this.setState({
        progress: 0,
        uploading: false,
        uploaded: false
      });
    };

    this._handleChange = e => {
      e.preventDefault();

      const {
        urlTarget,
        name,
        onLoadFileEnd,
        onUploadSuccess,
        onError,
        validation,
        bucket,
        presetid,
        fileValidation,
        onLoadFileStart
      } = this.props;

      const reader = new FileReader();
      const file = e.target.files[0];

      if (!file) {
        return;
      }

      onLoadFileStart && onLoadFileStart(file);

      reader.onloadend = () => {
        const fileType = this._getFileType(file);

        const isDocument = this._isDocument(file);
        const validateFiles = isDocument ? this._isDocumentValid(file) : this._isFileValid(validation, fileType, reader, file);

        // after loaded, validate the file
        validateFiles.then(isValid => {
          if (isValid && onLoadFileEnd) {
            this.setState({ status: 'valid' }, () => {
              // execute onLoadFileEnd with (e, file) argument on successful validation
              onLoadFileEnd(file, this.state.validationStatus);

              // on successful validation, it will sequentially upload the file to the server
              let type = '';
              if (urlTarget) {
                const isImage = this._isImage(file);
                const isVideo = this._isVideo(file);
                const isPdf = this._isPdf(file);
                const isDocument = this._isDocument(file);

                if (!isImage && !isVideo && !isPdf && !isDocument) {
                  type = 'general';
                } else if (isImage) {
                  type = 'image';
                } else if (isVideo) {
                  type = 'video';
                } else if (isPdf) {
                  type = 'pdf';
                } else if (isDocument) {
                  type = 'file';
                }
              }

              switch (type) {
                case 'general':
                  this._uploadFile(file, urlTarget, name, (0, _utils.ensureString)(bucket), (0, _utils.ensureString)(presetid)).then(result => {
                    if ((0, _utils.ensureObject)(result).type === 'error') {
                      onError && onError((0, _utils.ensureObject)(result).err, file);

                      this._resetProgress();
                    } else {
                      this._handleUploadFileSuccess(result, file);
                    }
                  });
                  break;
                case 'image':
                  this._uploadImage(file, urlTarget, name, (0, _utils.ensureString)(bucket), (0, _utils.ensureString)(presetid)).then(result => {
                    if ((0, _utils.ensureObject)(result).type === 'error') {
                      onError && onError((0, _utils.ensureObject)(result).err, file);

                      this._resetProgress();
                    } else {
                      this._handleUploadImageSuccess(result, file);
                    }
                  });
                  break;
                case 'video':
                  this._generateVideoSerial().then(data => {
                    if ((0, _utils.ensureObject)(data).type === 'error') {
                      onError(data, file);
                      this.setState({ status: 'error' });
                      this._resetProgress();
                    } else {
                      const serial = (0, _utils.ensureString)((0, _utils.ensureObject)((0, _utils.ensureObject)(data).data).serial);

                      const versionSerial = (0, _utils.ensureString)((0, _utils.ensureObject)((0, _utils.ensureObject)(data).data).versionSerial);

                      this._uploadVideo({ filename: serial, file, urlTarget }).then(res => {
                        if ((0, _utils.ensureObject)(res).type === 'error') {
                          onError(res, file);
                          this.setState({ status: 'error' });
                          return this._resetProgress();
                        } else {
                          const s3Url = (0, _utils.ensureString)((0, _utils.ensureObject)((0, _utils.ensureObject)(res).data).s3URL);

                          const message = (0, _utils.ensureString)((0, _utils.ensureObject)(res).message);

                          const status = (0, _utils.ensureString)((0, _utils.ensureObject)(res).status);

                          const data = {
                            serial,
                            versionSerial,
                            s3Url
                          };

                          const result = {
                            message,
                            status,
                            data
                          };

                          this._handleUploadVideoSuccess(result, file);
                        }
                      });
                    }
                  });
                  break;
                case 'pdf':
                  this._uploadPdf(file, urlTarget).then(result => {
                    if ((0, _utils.ensureObject)(result).type === 'error') {
                      onError && onError((0, _utils.ensureObject)(result).err, file);

                      this._resetProgress();
                    } else {
                      this._handleUploadFileSuccess(result, file);
                    }
                  });
                  break;
                case 'file':
                  this._uploadDocument(file, urlTarget).then(result => {
                    if ((0, _utils.ensureObject)(result).type === 'error') {
                      onError && onError((0, _utils.ensureObject)(result).err, file);

                      this._resetProgress();
                    } else {
                      this._handleUploadFileSuccess(result, file);
                    }
                  });
                  break;
                default:
                  break;
              }
            });
          } else {
            this.setState({ statusMessage: 'validation fails', status: 'error' }, () => {
              // it will return null as a second argument when validation fail
              onLoadFileEnd(isDocument ? file : null, //Note: type document need file detail
              this.state.validationStatus);
            });
          }
        });
      };

      reader.readAsDataURL(file);

      this.setState({ assetName: file.name });
    };

    this.state = INITIAL_STATE;
  }

  render() {
    const {
      name,
      id,
      urlTarget,
      extraContent,
      validation,
      value,
      placeholder,
      accept,
      bucket,
      presetid,
      buttonColor = 'grey',
      buttonBgColor = 'alto',
      disabled = false,
      customPreview
    } = this.props;

    const {
      progress,
      uploading,
      uploaded,
      status,
      statusMessage,
      assetName,
      assetURL
    } = this.state;

    if (!validation.format || !Array.isArray(validation.format)) {
      console.error('rg-inputfile require validate with format contains array of filetype');
    }

    return React.createElement(
      _uikit.Relative,
      null,
      React.createElement(
        React.Fragment,
        null,
        React.createElement(StyledInput, {
          type: 'file',
          name: name,
          id: id || name,
          onChange: this._handleChange,
          status: status,
          accept: (0, _utils.ensureString)(accept)
        }),
        React.createElement(
          StyledLabelFlex,
          {
            htmlFor: id || name,
            is: _uikit.Label,
            justifyContent: 'flex-end',
            alignItems: 'center',
            status: status,
            bg: 'white' },
          React.createElement(_uikit.Truncate, {
            children: value ? value.split('/').pop() : placeholder ? placeholder : '',
            ml: 3,
            color: 'gray'
          }),
          React.createElement(StyledTriggerFlex, {
            children: 'BROWSE',
            bg: buttonBgColor,
            color: buttonColor,
            p: 2,
            disabled: disabled
          })
        )
      ),
      urlTarget && React.createElement(_UploadedInfo2.default, {
        uploading: uploading,
        progress: progress,
        uploaded: uploaded,
        value: value,
        extraContent: extraContent,
        assetURL: assetURL,
        isInitialPreview: (0, _inputFileUtils.isImageURL)(value),
        onCancelUpload: this._handleCancelUpload,
        customPreview: customPreview
      })
    );
  }

  // why? from axios API
}

exports.default = InputFile;
const StyledInput = _styledComponents2.default.input`
  width: 0.1px;
  height: 0.1px;
  opacity: 0;
  overflow: hidden;
  position: absolute;
  z-index: -1;
`;

const StyledLabelFlex = (0, _styledComponents2.default)(_uikit.Flex)`
  justify-content: space-between;
  text-overflow: ellipsis;
  white-space: nowrap;
  cursor: pointer;
  overflow: hidden;
  vertical-align: middle;
  box-shadow: 0 0 0 1px ${props => _uikit.util.statusColor(props)};
  border-radius: ${props => _uikit.util.px(props.theme.radius)};
  margin-bottom: 0;
`;

const StyledTriggerFlex = (0, _styledComponents2.default)(_uikit.Flex)`
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  pointer-events: none;
  opacity: ${props => props.disabled ? 0.6 : 1};
`;