'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _react = require('react');

var React = _interopRequireWildcard(_react);

var _styledComponents = require('styled-components');

var _styledComponents2 = _interopRequireDefault(_styledComponents);

var _TextEditorUtils = require('../TextEditorUtils');

var _utils = require('utils');

var _uikit = require('uikit');

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _interopRequireWildcard(obj) { if (obj && obj.__esModule) { return obj; } else { var newObj = {}; if (obj != null) { for (var key in obj) { if (Object.prototype.hasOwnProperty.call(obj, key)) newObj[key] = obj[key]; } } newObj.default = obj; return newObj; } }

// $FlowFixMe
var StyleControls = function StyleControls(props) {
  var editorState = props.editorState,
      toggleBlockType = props.toggleBlockType,
      toggleInlineStyle = props.toggleInlineStyle,
      items = props.items;

  // Block style controls

  var selection = editorState.getSelection();

  var currentBlockType = editorState.getCurrentContent().getBlockForKey(selection.getStartKey()).getType();

  var filteredStyleTypes = (0, _TextEditorUtils.extractStyleTypes)(items);

  // Inline style controls

  var currentInlineStyle = editorState.getCurrentInlineStyle();

  return React.createElement(
    _uikit.Flex,
    null,
    filteredStyleTypes.map(function (filteredStyleType, index) {
      var type = filteredStyleType.type,
          style = filteredStyleType.style,
          render = filteredStyleType.render;


      switch (type) {
        case 'block':
          return React.createElement(
            _uikit.Box,
            { key: index, mr: 3 },
            render({
              isActive: style === currentBlockType,
              onMouseDown: function onMouseDown() {
                toggleBlockType((0, _utils.ensureString)(style));
              }
            })
          );

        case 'inline':
          return React.createElement(
            _uikit.Box,
            { key: index, mr: 3 },
            render({
              isActive: currentInlineStyle.has(style),
              onMouseDown: function onMouseDown() {
                toggleInlineStyle((0, _utils.ensureString)(style));
              }
            })
          );

        default:
          return null;
      }
    })
  );
};

exports.default = StyleControls;