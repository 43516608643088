module.exports = {
  /*
  'ruangkerja/cms/company-info': {
    data: {
      status: 'successs',
      data: {
        companyName: 'Pertamina',
        shortname: 'Pertamina',
        logo:
          'https://core-ruangguru.s3.amazonaws.com/assets/miscellaneous/image_fszjb2_5091.png',
        logoDashboard:
          'https://core-ruangguru.s3.amazonaws.com/assets/miscellaneous/image_vflrlm_6285.png',
        coverImage:
          'https://core-ruangguru.s3.amazonaws.com/assets/miscellaneous/image_5itlio_2132.png',
        companyserial: 'company1',
      },
    },
  },
  */
  '/ruangkerja/theme-config': {
    data: {
      status: 'success',
    },
  },

  'ruangkerja/cms/new-company': {
    data: {
      status: 'success',
    },
  },

  'ruangkerja/cms/company': {
    data: {
      status: 'success',
      data: {
        items: [
          {
            name: 'Pertamina Persero Edit',
            serial: 'parent1',
            type: 'company',
            child: [],
          },
        ],
      },
    },
  },
  'ruangkerja/cms/department': {
    data: {
      status: 'success',
      data: {
        items: [
          {
            name: 'Pertamina Persero Edit',
            serial: 'parent1',
            type: 'company',
            child: [
              {
                name: 'Hulu Edit',
                serial: 'departement1',
                type: 'department',
              },
            ],
          },
        ],
      },
    },
  },
  'ruangkerja/ruker-questions/QU-ntlmW3lS': {
    status: 'success',
    data: [
      {
        serial: 'QU-ntlmW3lS',
        type: 'Single choice',
        contents: 'What is lorem ipsume?',
        content_definitions: {
          text: 'lorem ipsume sit amet dolor. sit amet dolor',
          video_serial: '',
          video_url: '',
        },
        owner_id: 'COMP-XYZ',
        source: 'Ruang Kerja',
        status: 'Publish',
        answers: [
          {
            contents: 'Lorem ipsume sit amet dolor.',
            right_answers: true,
          },
          {
            contents: 'Lorem ipsume sit amet dolor.',
            right_answers: false,
          },
          {
            contents: 'Lorem ipsume sit amet dolor.',
            right_answers: false,
          },
          {
            contents: 'Lorem ipsume sit amet dolor.',
            right_answers: false,
          },
        ],
        correct_answers: [0],
        tags: ['COURSE-2U401297'],
        flag: 'ruker',
        created: {
          by: 'UOC-CA11D1361C',
          date: '2018-05-09 13:46:08',
        },
        updated: {
          by: 'UOC-CA11D1361C',
          date: '2018-05-09 13:46:08',
        },
      },
    ],
    messages: 'Success update',
  },
  /* 'ruangkerja/cms/course/course1/activate': {
   *   status: '200',
   *   message: 'active',
   *   data: {},
   * },
   * 'ruangkerja/cms/course/course2/activate': {
   *   status: '200',
   *   message: 'active',
   *   data: {},
   * },
   * 'ruangkerja/cms/course/course1/deactivate': {
   *   status: '200',
   *   message: 'deactive',
   *   data: {},
   * },
   * 'ruangkerja/cms/course/course2/deactivate': {
   *   status: '200',
   *   message: 'deactive',
   *   data: {},
   * },
   * 'ruangkerja/cms/course/course1': {
   *   status: '200',
   *   message: 'active',
   *   data: {courseSerial: 'course1'},
   * }, */
};
